/** @jsx jsx */

import { jsx } from "theme-ui"
import { useForm } from "react-hook-form";
import qs from 'query-string'
import { navigate } from 'gatsby';
import { countrySelectOptions } from "../../data/countries";
import languages from "../../data/languages";
import countryContinentMap from "../../data/country-continent-map";
import continents from "../../data/continents";
import * as yup from 'yup'
import { Label, Button, Heading } from 'theme-ui';
import { personalFields, localeFields, emailFields, candleJarContactFields, volunteeringFields, phoneFields } from './join-data';
import { TextInput, SelectInput, CheckboxInput } from './elements';
import { useTranslation, Trans } from 'react-i18next';
import { lenientlyMatchedLocale } from '../featured';
import { useAnalytics } from '../../data/analytics';
import countries from '../../data/countries';

export const fields = {
  ...emailFields,
  ...phoneFields,
  ...personalFields,
  ...localeFields,
  ...candleJarContactFields,
  ...volunteeringFields
}

export default function JoinFormInputs() {
  const analytics = useAnalytics()

  /**
   * Set up the form
   */

  const schema = yup.object({ ...fields })

  // const schema = useMemo(() => yup.object({
  //   // Manually ingested merge tags
  //   // TODO: Sync merge field types from Mailchimp via the API
  //   ...fields,
  //   // Mailchimp interest options loaded from the API
  //   ...mailchimpInterests.reduce((obj, o) => {
  //     obj[prefixMailchimpInterestId(o.id)] = yup
  //       .bool()
  //       .label(o.name)
  //     return obj
  //   }, {} as { [key: string]: yup.BooleanSchema<boolean> })
  // }), [mailchimpInterests])

  /**
   * Get some default values for the form
   */

  // Detect language from the browser locale
  const { i18n: { language }, t } = useTranslation()
  const matchedLanguageCode = lenientlyMatchedLocale(language, languages.map(i => i.isoName))
  const matchedLanguageOption = languages.find(l => l.isoName === matchedLanguageCode.locale)!

  // Preload any query strings from the URL into the form too
  const queryParams = qs.parse(typeof window === `undefined` ? '' : window.location.search)

  const defaultValues: yup.InferType<typeof schema> = schema.cast({
    ...queryParams,
    // language combo will first prioritize query param value, and then attempt
    // to auto-select based on current language ISO name
    LANGUAGE: queryParams.LANGUAGE || (matchedLanguageOption ? matchedLanguageOption.isoName : undefined)
  })

  const { register, handleSubmit, watch, errors, setValue } = useForm({
    validationSchema: schema,
    defaultValues
  });

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    const { LANGUAGE, EMAIL, PHONE, COUNTRY, CITY, FNAME, LNAME, candle_jar_contact, ...volunteering } = data

    // Post user data to Mailchimp
    try {
      const actionNetworkPayload = {
        merge_fields: {
          EMAIL,
          PHONE,
          FNAME,
          LNAME,
          LANGUAGE,
          CITY,
          COUNTRY: (countries as any)[COUNTRY],
          CONTINENT: (continents as any)[(countryContinentMap as any)[COUNTRY]],
          candle_jar_contact
        },
        volunteering: {
          ...volunteering
        },
        asMember: true
      }

      const an_payload = JSON.stringify(actionNetworkPayload)

      const signup_response = await fetch(`/.netlify/functions/signupUser`, {
        method: "POST",
        body: an_payload,
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })

      analytics.trackEvent({
        category: 'engagement',
        action: 'sign_up',
        customDimensions: [
          { id: 'method', value: 'individual-signup-form' }
        ]
      })

      const payload = qs.stringify({ ...actionNetworkPayload.volunteering, ...actionNetworkPayload.merge_fields, ...data })
      const host = typeof window === `undefined` ? 'http://localhost:8000' : window.location.protocol + "//" + window.location.host
      const successPath = `/join/individual/success?${payload}`
      const redirectPath = `/join/contribute`
      const cancelPath = `${window.location.pathname}?${payload}`

      if (signup_response.status == 200) {
        return navigate(redirectPath)
      } else {
        console.log("There's an issue.")
      }
    } catch (e) {
      console.error(e)
      return e
    }
  }

  /**
   * Render
   */

  const inputProps = { register, schema, errors, watch }

  const filteredLanguages = languages.filter(l => ["en", "es", "de", "fr", "pt-br"].includes(l.isoName))

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading variant='headings.2' sx={{ marginBottom: "8px" }}>
          <Trans>Tell us more about yourself</Trans>
        </Heading>
        <div sx={{ fontFamily: "IBM Plex Mono", fontSize: "13px" }}>
          <Trans>All fields marked with an asterisk &#40;*&#41; are required.</Trans>
        </div>

        <TextInput
          name='FNAME'
          {...inputProps}
        />

        <TextInput
          name='LNAME'
          {...inputProps}
        />

        <Heading variant='headings.3' sx={{ marginBottom: "8px", marginTop: "50px" }}>
          <Trans>Location</Trans>
        </Heading>
        <div sx={{ fontFamily: "IBM Plex Mono", fontSize: "13px" }}>
          <Trans>We'll use this to send you updates on actions, campaigns and events that are relevant to you.</Trans>
        </div>

        <SelectInput
          name='COUNTRY'
          {...inputProps}
          options={countrySelectOptions}
          renderOption={({ id, name }) => (
            <option key={id} value={id}>{t(name)}</option>
          )}
        />

        <TextInput
          name='CITY'
          {...inputProps}
        />

        <Heading variant='headings.3' sx={{ marginBottom: "8px", marginTop: "50px" }}>
          <Trans>Contact details</Trans>
        </Heading>
        <div sx={{ fontFamily: "IBM Plex Mono", fontSize: "13px" }}>
          <Trans>We'll use this to keep you up to date about things that may matter to you.</Trans>
        </div>

        <TextInput
          name='EMAIL'
          type='email'
          {...inputProps}
        />

        <TextInput
          name='PHONE'
          {...inputProps}
        />


        <SelectInput
          name='LANGUAGE'
          {...inputProps}
          options={filteredLanguages}
          renderOption={l => (
            <option key={l.isoName} value={l.isoName}>{l.name} / {l.nativeName}</option>
          )}
        />

        <Label><Trans>I am interested in volunteering for the Progressive International</Trans></Label>

        <CheckboxInput name="community_organising" {...inputProps}>
          <Trans>Community organising</Trans>
        </CheckboxInput>

        <CheckboxInput name="fundraising" {...inputProps}>
          <Trans>Fundraising</Trans>
        </CheckboxInput>

        <CheckboxInput name="translations" {...inputProps}>
          <Trans>Translations</Trans>
        </CheckboxInput>

        <CheckboxInput name="art_illustration" {...inputProps}>
          <Trans>Art &amp; illustration</Trans>
        </CheckboxInput>

        <CheckboxInput name="video_editing" {...inputProps}>
          <Trans>Video editing</Trans>
        </CheckboxInput>

        <CheckboxInput name="web_development" {...inputProps}>
          <Trans>Web development</Trans>
        </CheckboxInput>

        <CheckboxInput name="photography" {...inputProps}>
          <Trans>Photography</Trans>
        </CheckboxInput>

        <p sx={{ fontFamily: 'monospace' }}><Trans>
          By submitting this form you agree to be emailed occasionally by Progressive International for updates about campaigns.
        </Trans></p>

        <Button variant="primary" type="submit">
          Get Involved
        </Button>

        {!!Object.entries(errors).length && (
          <div sx={{ my: 3 }}>
            <div sx={{ fontWeight: 'bold', }}>Things you need to do:</div>
            <ul>
              {Object.entries(errors).map(([, error], i) => {
                return (
                  <li key={i} sx={{ variant: 'forms.error', fontWeight: 'bold', }}>{error?.message}</li>
                )
              })}
            </ul>
          </div>
        )}

        {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
        <div sx={{ position: "absolute", left: -5000, display: "none !important" }} aria-hidden="true">
          <input
            type="text"
            name="candle_jar_contact"
            tabIndex={-1}
            autoComplete="off"
            defaultValue=""
            ref={register()}
          />
        </div>
      </form>
    </div>
  )
}
