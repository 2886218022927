/** @jsx jsx */

import { jsx, Box, Heading } from 'theme-ui';
import JoinFormInputs from '../../components/joinform/join-individual';
import { useTranslation, Trans } from 'react-i18next';
import SEO from '../../components/seo';
import { PageRoot } from '../../data/content';
import { Fragment } from "react";

const JoinPage: PageRoot<{}> = ({ location: { pathname } }) => {
  const { t } = useTranslation()

  const chevron = require('../../images/chevron.svg')

  return (
    <Fragment>
      <SEO
        path={pathname}
        title={'Become a member'}
      />
      <Fragment>
        <Box sx={{
          px: 4,
          py: [4, 4, 6],
          top: 0,
          maxWidth: "700px",
          margin: "0px auto",
          color: "#280000"
        }}>
          <div sx={{ fontFamily: "IBM Plex Mono", fontSize: "12px", color: "#c4b4b3", display: "flex", paddingBottom: "40px", paddingTop: "35px" }}>
            <span sx={{ color: "#280000" }}><Trans>Your Details</Trans></span>
            <img src={chevron} sx={{ transform: "rotate(90deg)", width: "13px", marginLeft: "5px", marginRight: "5px" }} />
            <span><Trans>Support Us</Trans></span>
            <img src={chevron} sx={{ transform: "rotate(90deg)", width: "13px", marginLeft: "5px", marginRight: "5px" }} />
            <span sx={{ alignSelf: "center" }}><Trans>Thank You</Trans></span>
          </div>
          <JoinFormInputs />
          <Heading variant="forms.heading" sx={{ marginTop: "60px !important" }}>
            <Trans>Contribution rules</Trans>
          </Heading>
          <ul sx={{ fontFamily: "monospace", lineHeight: "1.4em", pl: "20px" }}>
            <li>
              <Trans>I am not an executive of a fossil fuel company.</Trans>
            </li>
            <li>
              <Trans>I am not a registered lobbyist.</Trans>
            </li>
            <li>
              <Trans>
                I am not an executive of a health insurance or pharmaceutical
                company.
              </Trans>
            </li>
            <li>
              <Trans>I am not an executive of a big tech company.</Trans>
            </li>
            <li>
              <Trans>I am not an executive of a big bank.</Trans>
            </li>
            <li>
              <Trans>I am not an executive of a private equity firm.</Trans>
            </li>
            <li>
              <Trans>I am not an executive of a hedge fund.</Trans>
            </li>
            <li>
              <Trans>I am not an executive at an agribusiness company.</Trans>
            </li>
          </ul>

          <Heading variant="forms.heading">
            <Trans>Privacy</Trans>
          </Heading>
          <div sx={{ fontFamily: "IBM Plex Mono" }}>
            <Trans>
              The Progressive international is committed to protecting your privacy, including online, and in the transparent use of any online information.
              <br /><br />
              Our Privacy Policy sets out in detail the purposes for which we process your personal data, who we share it with, what rights you have in relation to that data and everything else we think is important for you to know.
            </Trans>
          </div>
        </Box>
      </Fragment>
    </Fragment >
  )
}

export default JoinPage